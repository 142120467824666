import {ref} from "vue";
import store from "@/store/index.js";
import {ElMessage, ElMessageBox,ElLoading} from "element-plus";

import CryptoJS from './crypto-js.min'



const loading = ref(null);

export const showLoading = () => {
    loading.value = ElLoading.service({
        lock: true,
        text: '处理中，请稍后',
        background: 'rgba(0, 0, 0, 0.7)'
    });
};

export const closeLoading = () => {
    if (loading.value) {
        loading.value.close();
    }
};

const loadingCount = ref(0);

export const addLoading = async () => {
    loadingCount.value++;
    await store.dispatch('basic/up_loading', true);
}

export const isCloseLoading = async () => {
    loadingCount.value--;
    if (loadingCount.value === 0) {
        await store.dispatch('basic/up_loading', false);
    }
}

export const listToLevel = ({list = [], keys = '', pid = '0'}) => {
    if (list.length === 0) {
        return []
    }
    let res = [];
    list.forEach(item => {
        if (item['parent_id'] == pid) {
            item['children'] = listToLevel({list, keys, pid: item[keys]});
            res.push(item)
        }
    })
    return res;
}

export const err_message = msg => {
    ElMessage.error(msg);
}

export const suc_message = () => {
    ElMessage.success('成功')
}

export const confirm = msg => {
    return new Promise((resolve, reject) => {
        ElMessageBox.confirm(msg ?? '确定删除吗，删除后无法恢复！', '系统提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
        }).then(() => {
            resolve(true)
        }).catch(() => {
            resolve(false)
        })
    })

}

export const err_alert = msg => {
    ElMessageBox.alert(msg, '系统提示', {
        confirmButtonText: '知道！',
        type: 'warning'
    }).then(r => r).catch(e => e);
}

//数据加密
export const encrypt = data => {
    return CryptoJS.AES.encrypt(
        data,
        CryptoJS.enc.Utf8.parse('daBmT2PBEJVrZ8iR'),
        {
            mode: CryptoJS.mode.CBC,
            iv: CryptoJS.enc.Utf8.parse('bGHmtkhefz7sI4k8'),
            padding: CryptoJS.pad.Pkcs7
        }
    ).toString();
}
//数据解密
export const decrypt = data => {
    return CryptoJS.AES.decrypt(
        data,
        CryptoJS.enc.Utf8.parse('daBmT2PBEJVrZ8iR'),
        {
            mode: CryptoJS.mode.CBC,
            iv: CryptoJS.enc.Utf8.parse('bGHmtkhefz7sI4k8'),
            padding: CryptoJS.pad.Pkcs7
        }
    ).toString(CryptoJS.enc.Utf8)
}

export const exportFindItem = (find, data, key) => {
    let list = [];
    find.forEach(row => {
        let obj = data.find(item => item[key] === row);
        if (obj) {
            list.push(obj)
        }
    })
    return JSON.parse(JSON.stringify(list))
}

/**
 * 提炼导出的各字段标题和对应的key，返回对象包包含值：title_array,keys_array,number_array
 * @param data 数据源
 * @param title_key 数据源中标题key，如 label:订单号
 * @param valueKey 数据源中值key，如 prop:order_no
 * @return object
 * */
export const getExportTitleAndValueKeys = (data, title_key, valueKey) => {
    let title_array = [];
    let keys_array = [];
    let number_array = [];
    data.forEach(item => {
        title_array.push(item[title_key]);
        keys_array.push(item[valueKey]);
        if (item['is_number']){
            number_array.push(item[valueKey])
        }
    })
    return {
        title_array,
        keys_array,
        number_array
    }
}

/**
 * 获取当前时间
 * @param link 连接符，默认为 -
 * @param is_time 返回是否带有时分秒，默认true
 * */
export const getCurrentTime = (link, is_time) => {
    let links = link ?? '-';
    let is_times = is_time ?? true;
    let date = new Date();
    let str = date.getFullYear() + links + (date.getMonth() + 1) + links + date.getDate();
    if (is_times) {
        str = str + links + date.toLocaleTimeString();
    }
    return str;
}



