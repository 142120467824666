import axios from "axios";
import store from "@/store/index.js";
import {getBaseUrl} from "@/utils/config";
import {ElMessage} from "element-plus";
import router from "@/router";

const http = ({url, data, tip = false,files = false}) => {
    //token处理
    axios.interceptors.request.use(
        function (config) {
            let user = store.getters['basic/get_user'];
            if (user) {
                config.headers.authorization = user.token;
            }
            return config;
        },
        function (err) {
            return Promise.reject(err)
        }
    )

    return new Promise((resolve, reject) => {
        axios.post(getBaseUrl() + url, data,{
            "Content-Type": files ? "multipart/form-data;" : "application/json"
        }).then(res => {
            if (res.data.code === -10001){
                router.push({path:'/login'})
            }
            if (tip) {
                ElMessage({
                    message: res.data.msg,
                    type: res.data.code === 0 ? 'success' : 'error'
                })
            }
            resolve(res.data);
        }).catch(error => {
            reject(error)
        })
    })
}

export default http;