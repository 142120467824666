
const state = {
    user:null,
    loading:false,
    action:null,
    pageList:[],
    page:'',
}
const mutations = {
    set_user(state,data){
        if (!data){
            Object.keys(state).forEach(keys => {
                if (keys === 'page'){
                    state[keys] = '';
                }else if (keys === 'pageList'){
                    state[keys] = [];
                }else {
                    state[keys] = null;
                }
            })
        }else {
            state.user = data;
        }
    },
    set_loading(state,data){
        state.loading = data;
    },
    set_action(state,data){
        state.action = data;
    },
    set_pageList(state,data){
        state.pageList = data;
    },
    set_page(state,data){
        state.page = data;
    }
}
const actions = {
    up_user({commit},data){
        commit('set_user',data);
    },
    up_loading({commit},data){
        commit('set_loading',data);
    },
    up_action({commit},data){
        commit('set_action',data);
    },
    up_pageList({commit},data){
       commit('set_pageList',data);
    },
    up_page({commit},data){
        commit('set_page',data);
    }
}
const getters = {
    get_user(state){
        return state.user;
    },
    get_loading(state){
        return state.loading;
    },
    get_action(state){
        return state.action ?? [];
    },
    get_pageList(state){
        return state.pageList;
    },
    get_page(state){
        return state.page;
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};