import { createStore } from 'vuex'

const dynamicModules = {};

const files = require.context("./", true, /\.js$/);

files.keys().forEach((item) => {
  if (item !== "./api.js") {
    let moduleName = item.split("/").pop().replace(/\.js$/, "");
    let moduleFile = files(item);
    dynamicModules[moduleName] = moduleFile.default || moduleFile;
  }
});

export default createStore({
  modules: dynamicModules,
});
