import {createRouter, createWebHistory} from 'vue-router'
import Layout from "@/views/layout/index";
import NProgress from 'nprogress';
import 'nprogress/nprogress.css';
import store from "@/store/index";
import {updatePage} from "@/utils/menu";
import {listToLevel} from "@/utils/common";

NProgress.configure({showSpinner: false})

const defaultPage = ['/login', '/home', '/404', '/notFound', '/menu/set'];

const routes = [
    {
        path: '/login',
        name: 'login',
        meta: {
            title: '登陆',
            hidden: true,
        },
        component: () => import('@/views/system/Login.vue')
    },
    {
        path: '/',
        name: 'layout',
        redirect: '/home',
        component: Layout,
        children: [
            {
                path: '/home',
                name: 'home',
                meta: {
                    title: '首页',
                    hidden: true,
                },
                component: () => import('@/views/HomeView.vue')
            },
            {
                path: '/notFound',
                name: 'notFound',
                meta: {
                    title: 'notFound',
                    hidden: false
                },
                component: () => import('@/views/system/NotFound')
            },
            {
                path: '/user/list',
                name: 'user-list',
                meta: {
                    title: '团队管理',
                    hidden: false
                },
                component: () => import('@/views/system/UserSet')
            },
            {
                path: '/roles/list',
                name: 'roles-list',
                meta: {
                    title: '角色管理',
                    hidden: false
                },
                component: () => import('@/views/system/RolesSet')
            },
            {
                path: '/menu/set',
                name: 'menu-set',
                meta: {
                    title: '菜单管理',
                    hidden: false
                },
                component: () => import('@/views/system/MenuSet')
            },
            {
                path:'/entrepot',
                name:'entrepot',
                meta:{
                    title:'仓库管理',
                    hidden: false,
                },
                component: () =>  import('@/views/system/entrepot')
            },
            {
                path: '/custom',
                name:'custom',
                meta:{
                    title:'客户管理',
                    hidden: false
                },
                component: () =>  import('@/views/operation/custom')
            },
            {
                path:'/area',
                name:'area',
                meta:{
                    title:'行政区划',
                    hidden: false
                },
                component: () => import('@/views/system/area')
            },
            {
                path:'/station',
                name:'station',
                meta:{
                    title:'目的地管理',
                    hidden: false
                },
                component: () =>  import('@/views/system/station')
            },
            {
                path:'/order-input',
                name:'order-input',
                meta:{
                    title:'订单录入',
                    hidden: false,
                    keepAlive:true
                },
                component: () => import('@/views/order/order-input')
            },
            {
                path:'/order-list',
                name:'order-list',
                meta:{
                    title:'订单列表',
                    hidden: false,
                },
                component: () => import('@/views/order/order-list')
            },
            {
                path:'/order/send-list',
                name:'order-send',
                meta:{
                    title:'本站发出列表',
                    hidden: false,
                },
                component: () => import('@/views/order/send-list')
            },
            {
                path:'/order/trans-list',
                name:'order-trans',
                meta:{
                    title:'途径本站列表',
                    hidden: false,
                },
                component: () => import('@/views/order/trans-list')
            },
            {
                path:'/order/end-list',
                name:'order-end',
                meta:{
                    title:'到达本站列表',
                    hidden: false,
                },
                component: () => import('@/views/order/end-list')
            },
            {
                path:'/order/send-stock',
                name:'send-stock',
                meta:{
                    title:'发出库存',
                    hidden: false,
                },
                component: () => import('@/views/order/send-stock')
            },
            {
                path:'/order/trans-stock',
                name:'trans-stock',
                meta:{
                    title:'中转库存',
                    hidden: false
                },
                component: () => import('@/views/order/trans-stock')
            },
            {
                path:'/order/end-stock',
                name:'end-stock',
                meta:{
                    title: '到达库存',
                    hidden: false
                },
                component: () => import('@/views/order/end-stock')
            },
            {
                path:'/finance-custom',
                name:'finance-custom',
                meta:{
                    title:'客户对账',
                    hidden: false
                },
                component: () => import('@/views/finance/finance-custom')
            },
            {
                path:'/agent-list',
                name:'agent-list',
                meta:{
                    title:'代理/业务',
                    hidden: false
                },
                component: () => import('@/views/operation/agent')
            },
            {
                path:'/expense-list',
                name:'expense-list',
                meta:{
                    title:'收费科目',
                    hidden: false,
                },
                component: () => import('@/views/operation/expense')
            },{
                path: '/order-expense/list',
                name:'order-expense',
                meta:{
                    title:'结算订单',
                    hidden: false
                },
                component: () => import('@/views/operation/order-expense')
            },
            {
                path:'/order/change',
                name:'order-change',
                meta:{
                    title:'订单修改',
                    hidden: false
                },
                component: () => import('@/views/order/change-page')
            },
            {
                path:'/price/normal-price',
                name:'normal-price',
                meta:{
                    title:'标准定价(公布价)',
                    hidden: false
                },
                component: () => import('@/views/price/normal-price')
            },
            {
                path:'/delivery-house',
                name:'delivery-house',
                meta:{
                    title:'交货仓库管理',
                    hidden: false,
                },
                component: () => import('@/views/network/delivery-house')
            },
            {
                path:'/app-user',
                name:'app-user',
                meta:{
                    title: 'app 用户管理',
                    hidden: false
                },
                component: () =>  import('@/views/network/app-user')
            },
            {
                path:'/courier-order',
                name:'courier-order',
                meta: {
                    title: '网单管理',
                    hidden: false
                },
                component: () => import('@/views/network/courier-order')
            },
            {
                path:'/order/search',
                name:'order-search',
                meta:{
                    title:'运单追踪',
                    hidden: false,
                },
                component: () =>  import('@/views/order/order-search')
            },
            {
                path:'/tracking/change',
                name:'tracking-change',
                meta: {
                    title:'轨迹修改',
                    hidden: false
                },
                component: () => import('@/views/order/tracking-change')
            },
            {
                path:'/app/version-manager',
                name:'app-version',
                meta:{
                    title:'app版本管理',
                    hidden: false
                },
                component: () => import('@/views/system/app-version')
            },
            {
                path:'/order/sign-list',
                name:'order-sign-list',
                meta: {
                    title:'订单签收列表',
                    hidden: false
                },
                component: () => import('@/views/order/order-sign-list')
            }
        ]
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

router.beforeEach((to, from, next) => {
    NProgress.start();

    //在页面加载时读取sessionStorage里的状态信息
    if (window.sessionStorage.getItem("store")) {
        store.replaceState(
            Object.assign({}, JSON.parse(sessionStorage.getItem("store")))
        );
        window.sessionStorage.removeItem("store");
    }

    //检测是否登陆
    let user = store.getters['basic/get_user'];
    if (!user && to.path !== '/login') {
        next({
            path: '/login'
        });
    } else {

        if (to.path === '/login') {
            store.dispatch('basic/up_user', null).then(r => r);
        }

        //动态路由注入
        let action = store.getters['basic/get_action'];
        //检测权限
        let page = action.find(item => '/' + item['path'] === to.path);
        let isDefault = defaultPage.includes(to.path);
        if (to.matched.length === 0 || (!page && !isDefault)) {
            next({
                path: '/notFound'
            })
        } else {
            next();
        }
        updatePage(to).then(r => r);
    }
    document.title = to.meta.title ? 'Faithful:' + to.meta.title : 'Faithful';

})

router.afterEach(() => {
    NProgress.done();
})


const getMapRouters = (list) => {
    let storeRouters = listToLevel({list: list, keys: 'menu_id'});
    let data = [];
    storeRouters.forEach(row => {
        row.children.forEach(item => {
            data.push({
                path: item.path,
                name: item.title,
                meta: {
                    title: item.title,
                    hidden: false,
                },
                component: () => import(`../views/${item.component}`),
            })
        })
    })
    return [{
        path: '/',
        name: 'layout2',
        component: Layout,
        children: data
    }];
}

export default router
