
import http  from '@/utils/http'

const prefix = '/api/admin/v1/';

export default {
    update:{
        custom: data => {
            return http({url:prefix+'custom/allUpdate',data,tip:true}).then(r => r);
        }
    },
    basic:{
        exportExcel: data => {
            /* list,file_name,title_list,keys_list,to_number_list */
            return http({url:prefix+'basic/exportExcel',data,tip:true}).then(r => r);
        },
        uploadFile: data => {
            return http({url:prefix+'basic/uploadFile',data,tip:true,file:true}).then(r => r);
        }
    },
    image:{
        remove: data => {
            return http({url:prefix+'basic/removeImage',data}).then(r => r);
        },
        uploadLicense: data => {
            return http({url:prefix+'company/uploadLicense',data}).then(r => r);
        },
    },
    menu:{
        save: data => {
            return http({url:prefix+'menu/save',data,tip:true}).then(r => r);
        },
        list: data => {
            return http({url:prefix+'menu/list',data}).then(r => r);
        },
        delete: data => {
            return http({url:prefix+'menu/delete',data,tip:true}).then(r => r);
        },
        listToLevel: () => {
            return http({url:prefix+'menu/listToLevel'}).then(r => r);
        }
    },
    roles:{
        save: data => {
            return http({url:prefix+'roles/save',data,tip:true}).then(r => r);
        },
        list: data => {
            return http({url:prefix+'roles/list',data}).then(r => r);
        },
        delete: data => {
            return http({url:prefix+'roles/delete',data,tip:true}).then(r => r);
        },
        setPower: data => {
            return http({url:prefix+'roles/setPower',data,tip:true}).then(r => r);
        },
        getAll: () => {
            return http({url:prefix+'roles/getAll'}).then(r =>  r);
        }
    },
    user:{
        register: data => {
            return http({url:prefix+'user/register',data}).then(r => r);
        },
        login: data => {
            return http({url:prefix+'user/login',data}).then(r => r);
        },
        save: data => {
            return http({url:prefix+'user/save',data,tip:true}).then(r => r);
        },
        list: data => {
            return http({url:prefix+'user/list',data}).then(r => r);
        },
        delete: data => {
            return http({url:prefix+'user/delete',data,tip:true}).then(r => r);
        },
        updatePassword: data => {
            return http({url:prefix+'user/changePassword',data,tip:true}).then(r => r);
        },
        appUserList: data => {
            return http({url:prefix+'user/appUserList',data}).then(r => r);
        },
        appUserUpdate: data => {
            return http({url:prefix+'user/appUserUpdate',data,tip:true}).then(r => r);
        },
        searchAppUser: data => {
            return http({url:prefix+'user/searchAppUser',data}).then(r => r);
        },
        resetPassword: data => {
            return http({url:prefix+'user/resetAppUserPassword',data,tip:true}).then(r => r);
        },
        batchSaveAppUser: data => {
            return http({url:prefix+'user/batchSaveAppUser',data,tip:true}).then(r => r);
        }
    },
    entrepot:{
        save: data => {
            return http({url:prefix+'entrepot/save',data,tip:true}).then(r => r);
        },
        list: data => {
            return http({url:prefix+'entrepot/list',data}).then(r => r);
        },
        delete: data => {
            return http({url:prefix+'entrepot/delete',data,tip:true}).then(r => r);
        },
        getListByType: data => {
            return http({url:prefix+'entrepot/getListByType',data}).then(r => r);
        },
        getAll: () => {
            return http({url:prefix+'entrepot/getAll'}).then(r => r);
        },
        searchKeyword: data => {
            return http({url:prefix+'entrepot/searchKeyword',data}).then(r => r);
        },
        getMyOptionHouseList: () => {
            return http({url:prefix+'entrepot/getMyOptionHouseList'}).then(r => r);
        }
    },
    agent:{
        save: data => {
            return http({url:prefix+'agent/save',data,tip:true}).then(r => r);
        },
        getListByType: data => {
            return http({url:prefix+'agent/getListByType',data}).then(r => r);
        },
        list: data => {
            return http({url:prefix+'agent/list',data}).then(r => r);
        },
        delete: data => {
            return http({url:prefix+'agent/delete',data,tip:true}).then(r => r);
        },
        edit: data => {
            return http({url:prefix+'agent/edit',data,tip:true}).then(r => r);
        }
    },
    area:{
        list: data => {
            return http({url:prefix+'area/list',data}).then(r => r);
        },
        searchKeyword: data => {
            return http({url:prefix+'area/searchKeyword',data}).then(r => r);
        }
    },
    station:{
        create: data => {
            return http({url:prefix+'station/create',data,tip:true}).then(r => r);
        },
        list: data => {
            return http({url:prefix+'station/list',data}).then(r => r);
        },
        delete: data => {
            return http({url:prefix+'station/delete',data,tip:true}).then(r => r);
        },
        getAll : () => {
            return http({url:prefix+'station/getAll'}).then(r => r);
        }
    },
    custom:{
        save: data => {
            return http({url:prefix+'custom/save',data,tip:true}).then(r => r);
        },
        list: data => {
            return http({url:prefix+'custom/list',data}).then(r => r);
        },
        delete: data => {
            return http({url:prefix+'custom/delete',data,tip:true}).then(r => r);
        },
        search: data => {
            return http({url:prefix+'custom/search',data}).then(r => r);
        },
        get: data => {
            return http({url:prefix+'custom/getCustom',data}).then(r => r);
        },
        searchCustomNo: data => {
            return http({url:prefix+'custom/searchCustomNo',data}).then(r => r);
        },
        getCustomByCustomNo: data => {
            return http({url:prefix+'custom/getCustomByCustomNo',data}).then(r => r);
        },
    },
    order:{
        create: data => {
            return http({url:prefix+'order/create',data,tip:true}).then(r => r);
        },
        list: data => {
            return http({url:prefix+'order/list',data}).then(r => r);
        },
        sendStockList: data => {
            return http({url:prefix+'order/sendStockList',data}).then(r => r);
        },
        transStockList: data => {
            return http({url:prefix+'order/transStockList',data}).then(r => r);
        },
        endStockList: data => {
            return http({url:prefix+'order/endStockList',data}).then(r => r);
        },
        inputCabinet: data => {
            return http({url:prefix+'order/inputCabinet',data,tip:true}).then(r => r);
        },
        inputTrans: data => {
            return http({url:prefix+'order/inputTrans',data,tip:true}).then(r => r);
        },
        transInStock: data => {
            return http({url:prefix+'order/transInStock',data,tip:true}).then(r => r);
        },
        transOutStock: data => {
            return http({url:prefix+'order/transOutStock',data,tip:true}).then(r => r);
        },
        sign: data => {
            return http({url:prefix+'order/sign',data,tip:true}).then(r => r);
        },
        financeList: data => {
            return http({url:prefix+'order/financeList',data}).then(r => r);
        },
        listByCustom: data => {
            return http({url:prefix+'order/listByCustom',data}).then(r => r);
        },
        saveBill: data => {
            return http({url:prefix+'order/saveBill',data,tip:true}).then(r => r);
        },
        detail: data => {
            return http({url:prefix+'order/getOrderDetail',data}).then(r => r);
        },
        getPrintOrderInfo: data => {
            return http({url:prefix+'order/getPrintOrderInfo',data}).then(r => r);
        },
        changeOrder: data => {
            return http({url:prefix+'order/changeOrder',data,tip:true}).then(r => r);
        },
        delete: data => {
            return http({url:prefix+'order/delete',data,tip:true}).then(r => r);
        },
        statusList: () => {
            return http({url:prefix+'order/getOrderStatusList'}).then(r => r);
        },
        movement:data => {
            return http({url:prefix+'order/movement',data,tip:true}).then(r => r);
        },
        addOrderExpense: data => {
            return http({url:prefix+'order/addOrderExpense',data,tip:true}).then(r => r);
        },
        searchOrderByType: data => {
            return http({url:prefix+'order/searchOrderByType',data}).then(r => r);
        },
        orderAddTracking: data => {
            return http({url:prefix+'order/orderAddTracking',data,tip:true}).then(r => r);
        },
        fanYi: data => {
            return http({url:prefix+'order/fanYi',data}).then(r => r);
        },
        getNormalSignList: data => {
            return http({url:prefix+'order/getNormalSignList',data}).then(r => r);
        }
    },
    count:{
        notCabinet: () => {
            return http({url:prefix+'order/getEntrepotNotCabinetCount'}).then(r => r);
        },
        getOrderCount: () => {
            return http({url:prefix+'order/getOrderCount'}).then(r => r);
        },
    },
    bill:{
        getBillListByCustom: data => {
            return http({url:prefix+'bill/getBillListByCustom',data}).then(r => r);
        },
        billPay: data => {
            return http({url:prefix+'bill/billPay',data,tip:true}).then(r => r);
        }
    },
    expense:{
        save: data => {
            return http({url:prefix+'expense/save',data,tip:true}).then(r => r);
        },
        list: data => {
            return http({url:prefix+'expense/list',data}).then(r => r);
        },
        delete: data => {
            return http({url:prefix+'expense/delete',data,tip:true}).then(r => r);
        },
        getAll: () => {
            return http({url:prefix+'expense/getAll',}).then(r => r);
        }
    },
    price:{
        save: data => {
            return http({url:prefix+'price/save',data,tip:true}).then(r => r);
        },
        list: data => {
            return http({url:prefix+'price/list',data}).then(r => r);
        },
        delete: data => {
            return http({url:prefix+'price/delete',data,tip:true}).then(r => r);
        }
    },
    house:{
        list: data => {
            return http({url:prefix+'deliveryHouse/list',data}).then(r => r);
        },
        create: data => {
            return http({url:prefix+'deliveryHouse/create',data}).then(r => r);
        },
        delete: data => {
            return http({url:prefix+'deliveryHouse/delete',data}).then(r => r);
        }
    },
    netOrder:{
        list: data => {
            return http({url:prefix+'courierOrder/list',data}).then(r => r);
        },
        arrival: data => {
            return http({url:prefix+'courierOrder/arrival',data,tip:true}).then(r => r);
        },
        update: data => {
            return http({url:prefix+'courierOrder/update',data,tip:true}).then(r => r);
        },
        getHouse: () => {
            return http({url:prefix+'station/getHouse'}).then(r => r);
        },
        create: data => {
            return http({url:prefix+'courierOrder/create',data,tip:true}).then(r => r);
        }
    },
    version:{
        save: data => {
            return http({url:prefix+'appVersion/save',data,tip:true}).then(r => r)
        },
        list: data => {
            return http({url:prefix+'appVersion/list',data}).then(r => r);
        }
    }
}
