import store from "@/store/index";
import router from "@/router/index";

export const openPage = async (row) => {
    await router.push({path: '/' + row.path});
}

export const closePage = async (row) => {
    let list = await JSON.parse(JSON.stringify(store.getters['basic/get_pageList']));
    let findIndex = list.findIndex(item => item.path === row.path);
    let len = list.length;
    let page = {path: '/home'};
    if (findIndex === len - 1) {
        if (len !== 1) {
            //跳至最后一个
            page.path = list[findIndex - 1].path;
        }
    } else {
        //非最后一项
        page.path = list[findIndex + 1].path;
    }
    list.splice(findIndex, 1);
    await store.dispatch('basic/up_pageList', list);
    await router.push({path: page.path});
}

export const updatePage = async (row) => {
    if (row.matched.length > 0) {
        let list = await store.getters['basic/get_pageList'];
        let action = await store.getters['basic/get_action'];
        list = JSON.parse(JSON.stringify(list));
        let isFind = list.find(item => item.path === row.path);
        let isAction = action.find(item => '/' + item.path === row.path);
        if (!isFind && !row.meta.hidden) {
            list.push({
                path: row.path,
                title: isAction ? isAction.title : row.meta.title,
                close: true,
            })
        }
        await store.dispatch('basic/up_pageList', list);
        await store.dispatch('basic/up_page', row.path);
    }

}